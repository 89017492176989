import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: normal;
    src: url(../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeo.eot); /* IE9 Compat Modes */
    src: url(../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeo.eot?#iefix) format(embedded-opentype), /* IE6-IE8 */
    url(../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeo.woff2) format(woff2), /* Super Modern Browsers */
    url(../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeo.woff) format(woff), /* Modern Browsers */
    url(../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeo.ttf) format(truetype)
  }

  * {
    // background: #000 !important;
    // color: #0f0 !important;
    // outline: solid #f00 1px !important;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Apple SD Gothic Neo' !important;
    background-color: #f1f1f1;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label,
  button {
    font-family: 'Apple SD Gothic Neo' !important;
    line-height: 1.5em;
  }

  input, select, textarea {
    font-family: 'Apple SD Gothic Neo' !important;
    font-size: inherit;
  }

  .input-no-border {
    &::before, &::after {
      border-bottom: none !important;
    }
  }

  button {
    outline: none !important;
  }

  .highlight-class {
    background-color: yellow !important;
  }
  
  .fc-icon.fc-icon-chevron-left, .fc-icon.fc-icon-chevron-right {
    :before {
      vertical-align: baseline !important;
    }
  }

  .fc .fc-button-primary {
    background: #e06736 !important;
    border-color: #e06736 !important;
  }

  .fc-daygrid-block-event .fc-event-title {
    font-size: 1.25em !important;
    padding: 6px 0 !important;
  }
`;
